<template>
  <div>
    <label class="flex items-center gap-x-1 px-2 border group transition opacity-90 hover:opacity-100 cursor-pointer hover:dark:bg-zinc-800 hover:dark:border-zinc-600 dark:border-zinc-700 block w-full rounded-md sm:text-sm">
      <icon-search class="w-4 opacity-40" />
      <input 
        ref="searchInput" 
        :autofocus="props.autofocus"
        v-model="searchQuery"
        type="search" 
        @focus="checkRoute"
        placeholder="Procure por eventos, shows, cursos, festas..."
        class="bg-transparent ring-0 outline-0 w-full block p-2 inline pr-2 transition group-hover:opacity-100 opacity-60"
        @input="onInput" />
    </label>
  </div>
</template>

<script setup>
const searchQuery = ref('')
const searchInput = ref(null)

const route = useRoute()
const router = useRouter()

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false
  },
  changeUrlQuery: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['onQueryChanged', 'onDebouncedChanged'])

const debounce = (func, timeout) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

watch(searchQuery, debounce((newValue) => {
  router.push({ path: route.path, query: { search: newValue } })
}, 300))

watch(() => route.name, () => {
  if (route.name !== 'busca') {
    searchQuery.value = ''
    route.query.search = ''
  }
  else if (route.query.search) {
    searchQuery.value = route.query.search
  }
});

watch(() => route.query.search, () => {
  searchQuery.value = route.query.search
});

onMounted(() => {
  if (props.autofocus)
    searchInput.value.focus()
  if (route.query.search) {
    searchQuery.value = route.query.search
  }
})

const onInput = () => {
  emit('onQueryChanged', searchQuery.value);
  debounce(() => {
    emit('onDebouncedChanged', searchQuery.value);
  }, 300)();
}



const checkRoute = () => {
  if (route.name !== 'busca')
    router.push({ name: 'busca', query: { search: searchQuery.value } })
}

</script>